import type { BoxProps } from '@chakra-ui/react'
import { Box, Container, Flex, Heading } from '@chakra-ui/react'
import { Highline } from 'custom_components/elements/Highline/Highline'
import { formatHeadlineColor } from 'helper/formatHeadlineColor'
import NextImage from 'next/image'
import type React from 'react'

interface IHeroSimpleProps {
  headline: string
  image: IImage
}

interface IColor {
  colorAccent?: string
  colorText?: string
}
interface ICombined extends IColor, IHeroSimpleProps, BoxProps {}

export const HeroSimple: React.FC<ICombined> = ({
  image, headline,
  colorAccent = 'accent.500', colorText = 'primaryText.500',
  ...boxProps
}) => {
  return (
    <Box
      w="100%"
      h={{ base: '30vh', md: '700px' }}
      position="relative"
      backgroundColor="black"
      mb={12}
      {...boxProps}
    >
      <Box
        position="absolute"
        display="block"
        bottom={0}
        top={0}
        left={0}
        right={0}
        opacity={0.8}
      >
        <Box w="100%" h="100%" position="relative">
          <NextImage
            priority
            layout="fill"
            src={`${image.fullpath ?? '/'}`}
            objectFit="cover"
            placeholder={image.blurDataUrl ? 'blur' : 'empty'}
            blurDataURL={image.blurDataUrl}
            quality={100} // TODO go over HeroSimple and check if we can use a lower quality
          />
        </Box>
      </Box>
      <Container
        maxW="100vw"
        h="100%"
        pos="relative"
      >
        <Flex
          align="flex-end"
          h="100%"
          py={{ base: '15px', md: '48px' }}
          ml={{ xl: '50vw' }}
          px="15px"
        >
          <Highline colorAccent={colorAccent}>
            <Heading
              fontSize={{ base: '2xl', md: '5xl' }}
              as="h1"
              fontWeight="700"
              color={colorText}
            >{formatHeadlineColor(headline)}
            </Heading>
          </Highline>
        </Flex>
      </Container>
    </Box>
  )
}

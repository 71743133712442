import { Box, Button, Container, Flex, Heading, Stack, VStack } from '@chakra-ui/react'
import { Highline } from 'custom_components/elements/Highline/Highline'
import { Carousel } from 'design_system/compounds/Carousel/Carousel'
import { CarouselButtons } from 'design_system/compounds/CarouselButtons/CarouselButtons'
import { NextLink } from 'design_system/utility/NextLink'
import NextImage from 'next/image'
import type React from 'react'
import type { ReactNode } from 'react'
import { useState } from 'react'

export interface IHeroSwiperProps {
  slides: Array<{
    title: string
    text: ReactNode
    image: IImage
    linkText: string
    link: {
      href: { absolutePath: string | null }
      externalLink?: string
    }
  }>
}

// this is done to get the single element type from the slides array
type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends ReadonlyArray<infer ElementType> ? ElementType : never

const carouselImage = (slide: ArrayElement<ICombined['slides']>, index: number) => (
  <Box
    key={slide.title + String(index)}
    h={{ base: '307px', md: '860px' }}
    w={{ base: '100vw', md: '1188px' }}
    position="relative"
  >
    <NextImage
      priority
      layout="fill"
      src={`${slide.image.fullpath ?? '/'}?width=1600`}
      objectFit="cover"
      placeholder={slide.image.blurDataUrl ? 'blur' : 'empty'}
      blurDataURL={slide.image.blurDataUrl}
    />
  </Box>
)

const carouselHeading = (slide: ArrayElement<ICombined['slides']>, index: number, colorText: string) => (
  <Box
    key={`${slide.title}-${index}`}
    pt={{ base: '30px', md: '50px' }}
    pl={{ base: '0', md: '10px' }}
  >
    <Highline w={{ base: '100px', md: '114px' }}>
      <Heading
        as="h2"
        color={colorText}
        fontSize={{ base: '4xl', md: '5xl' }}
        textShadow="rgba(0, 0, 0, 0.6) 1px 2px 13px"
        lineHeight={{ base: '1.1', md: '1.3' }}
        mb={{ base: '2', md: '0' }}
      >
        {slide.title}
      </Heading>
    </Highline>
  </Box>
)

const carouselText = (slide: ArrayElement<ICombined['slides']>, index: number, colorText: string) => (
  <Box key={`${String(slide.text)}-${index}`}>
    <Box
      color={colorText}
      m={{ base: '0 0 24px 0', md: '24px 24px 24px 0' }}
      maxW={{ base: '100%', md: '80%' }}
    >
      {slide.text}
    </Box>
    {slide.link.href.absolutePath && (
      <NextLink href={slide.link.href.absolutePath} isExternal={!!slide.link.externalLink}>
        <Button
          variant="primary"
          px={{ base: '24px', lg: '48px' }}
          py={{ base: '1rem', lg: '16px' }}
          fontSize="sm"
          fontWeight="300"
          letterSpacing="1.75px"
        >{slide.linkText}
        </Button>
      </NextLink>
    )}

  </Box>
)

export const HeroSwiper: React.FC<ICombined> = ({
  slides,
  colorText = 'primaryText.500', colorBackground = 'primaryBackground.500',
}) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  return (
    <Container maxW="1920px" p={0}>
      <Box
      // Background stocker-black over container border
        display={{ base: 'none', md: 'inline' }}
        position="absolute"
        left={0}
        bgColor={colorBackground}
        w="100%"
        h="860px"
        clipPath="polygon(0 0, 100% 0, 100% 812px, 50vw 812px, 50vw 860px, 0 100%)"
      />
      {/* Desktop */}
      <Stack
        direction="row"
        spacing="0"
        display={{ base: 'none', md: 'flex' }}
      >
        <Carousel
          w={{ base: '100%', md: '60%' }}
          h={{ base: '307px', md: '860px' }}
          currentSlideIdx={currentSlide}
        >
          {slides.map((slide, index) => (
            <>
              {carouselImage(slide, index)}
            </>
          ))}
        </Carousel>
        <Box
          bgColor={colorBackground}
          w={{ base: '100%', md: '40%' }}
          h={{ base: '100%', md: '812px' }}
          position="relative"
        >
          <Flex
            justify="center"
            flexDirection="column"
            h="100%"
            w="100%"
          >
            <VStack align="start">
              <Carousel
                currentSlideIdx={currentSlide}
                ml={{ base: '0', md: '-100px' }}
                px={{ base: '1.5rem', md: '0' }}
                bg="transparent"
              >
                {slides.map((slide, idx) => (
                  <>
                    {carouselHeading(slide, idx, colorText)}
                  </>
                ))}
              </Carousel>
              <Carousel
                currentSlideIdx={currentSlide}
                ml="-100px"
                backgroundColor="transparent"
                px="1.5rem"
              >
                {slides.map((slide, idx) => (
                  <>
                    {carouselText(slide, idx, colorText)}
                  </>
                ))}
              </Carousel>
              <Box
                pt="24px"
                w="100%"
              >
                <CarouselButtons
                  position={{ base: 'static', md: 'absolute' }}
                  bottom={0}
                  right={0}
                  w={{ base: '100%', md: '422px' }}
                  h={{ base: '48px', md: '76px' }}
                  maxW="100%"
                  onClickLeft={() => { currentSlide > 0 && setCurrentSlide(currentSlide - 1) }}
                  onClickRight={() => { currentSlide < slides.length - 1 && setCurrentSlide(currentSlide + 1) }}
                  disableLeftButton={currentSlide === 0}
                  disableRightButton={currentSlide === slides.length - 1}
                />
              </Box>
            </VStack>
          </Flex>
        </Box>
      </Stack>
      {/* Mobile */}
      <Stack
        direction="column"
        spacing="0"
        display={{ base: 'flex', md: 'none' }}
      >
        <Carousel
          w={{ base: '100%', md: '60%' }}
          h={{ base: 'fit-content', md: '860px' }}
          currentSlideIdx={currentSlide}
        >
          <Flex>
            {slides.map((slide, index) => (
              <Box key={index}>
                {carouselImage(slide, index)}
                <Box p="1.5rem">
                  {carouselHeading(slide, index, colorText)}
                  {carouselText(slide, index, colorText)}
                </Box>
              </Box>
            ))}
          </Flex>
        </Carousel>
        <Box
          pt={{ base: 0, md: '24px' }}
          w="100%"
        >
          <CarouselButtons
            position={{ base: 'static', md: 'absolute' }}
            bottom={0}
            right={0}
            w={{ base: '100%', md: '422px' }}
            h={{ base: '48px', md: '76px' }}
            maxW="100%"
            onClickLeft={() => { currentSlide > 0 && setCurrentSlide(currentSlide - 1) }}
            onClickRight={() => { currentSlide < slides.length - 1 && setCurrentSlide(currentSlide + 1) }}
            disableLeftButton={currentSlide === 0}
            disableRightButton={currentSlide === slides.length - 1}
          />
        </Box>
      </Stack>
    </Container>
  )
}

interface IColors {
  colorText?: string
  colorBackground?: string
}
interface ICombined extends IColors, IHeroSwiperProps {}


interface IPimcoreImage {
  fullpath?: string | null
  data?: string | null
  mimetype?: string | null
}

export function asImageProps (image: IPimcoreImage | undefined | null) {
  return {
    fullpath: image?.fullpath ?? '',
    blurDataUrl: getDataUrl(image) ?? '',
  }
}

export function getDataUrl (image: IPimcoreImage | undefined | null) {
  if (image?.data && image.mimetype) {
    return `data:${image.mimetype};base64,${image.data}`
  }
}

export function asTextOrWarning (value: string | undefined | null, context: string): string {
  if (value) {
    return value
  } else {
    console.warn(`Warning: ${context} is empty, please provide a value`)
    return '[No Value Provided, please inform the content manager]'
  }
}

export function asLinkOrWarning (value: { absolutePath?: string | null } | null | undefined, context: string) {
  if (value?.absolutePath) {
    return { absolutePath: value.absolutePath }
  } else {
    console.warn(`Warning: ${context} is empty, please provide a value`)
    return { absolutePath: null }
  }
}

export function asDownloadText (filename: string | undefined | null, filesize: number | undefined | null, context: string, parsedExtension?: string) {
  if (!filename) {
    console.warn(`Warning: ${context}.filename is empty, please provide a value`)
    return '[No Value Provided, please inform the content manager]'
  } else {
    let [file, extension] = filename.split('.')

    // if no extension is set
    if (!extension) {
      extension = ''
    }

    if (!filesize) {
      console.warn(`Warning: ${context}.filesize is empty, something went wrong`)
      return `${file} (${(parsedExtension ?? extension).toUpperCase()})`
    } else {
      const asMb = (filesize / 1024 / 1024) > 1
      return `${file} (${(parsedExtension ?? extension).toUpperCase()} | ${(asMb ? filesize / 1024 / 1024 : filesize / 1024).toFixed(2)} ${asMb ? 'MB' : 'KB'})`
    }
  }
}

export function asDownloadLinkOrWarning (filename: string | undefined | null, context: string) {
  if (!filename) {
    console.warn(`Warning: ${context}.filename is empty, please provide a value`)
    return '/404'
  } else {
    return `${filename}`
  }
}

// Typing is a pain in the ass with the generated types, but it works (tested) as long as the query stays the same
export function asBreadcrumbs (content: any) {
  const data: Array<{ label: string, slug: string }> = []
  const getCrumbs = (content: any) => {
    data.push({ label: content?.name ?? '', slug: content?.absolutePath ?? '' })
    if (content?.parent?.name) {
      getCrumbs(content.parent)
    }
  }
  getCrumbs(content)
  return { data: data.reverse() }
}

import { Box, Flex, Heading, useTheme, VStack } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { AnimatedUnderline } from 'custom_components/elements/AnimatedUnderline/AnimatedUnderline'
import { HighlightFirstWord } from 'custom_components/elements/HighlightFirstWord/HighlightFirstWord'
import { NextLink } from 'design_system/utility/NextLink'
import NextImage from 'next/image'
import type React from 'react'
import type { ReactNode } from 'react'

export interface IProductLinesProps {
  image: IImage
  productLine?: string
  title: string
  text: ReactNode
  link: {
    absolutePath: string | null
  }
  linkText: string
}

interface IColors {
  colorBackground?: string
  colorBackgroundBox?: string
  colorText?: string
}
interface ICombined extends IColors, IProductLinesProps {}

export const ProductLineCard: React.FC<ICombined> = ({
  productLine, title, text, link, image, linkText,
  colorBackground = 'secondaryBackground.500',
  colorBackgroundBox = 'secondaryBackground.550',
  colorText = 'secondaryText.900',
}) => {
  const theme = useTheme()
  return (
    <Box
      py="10%"
      pr={{ base: 0, md: '15%' }}
      w="100%"
      position="relative"
      bgColor={colorBackground}
      color={colorText}
    >
      <Box
        bgColor={colorBackgroundBox}
        maxW="700px"
        _after={{
          // trick to make it square
          content: "''",
          display: 'block',
          paddingBottom: '100%',
        }}
        position="relative"
      >
        <Flex
          position="absolute"
          height="110%"
          width="100%"
          justify="center"
          ml={{ base: '-20%', lg: '-5%' }}
          top="-5%"
        >
          <Box w="100%" h="100%">
            <NextImage
              src={image.fullpath ?? ''}
              layout="fill"
              objectFit="contain"
              placeholder={image.blurDataUrl ? 'blur' : 'empty'}
              blurDataURL={image.blurDataUrl}
            />
          </Box>
        </Flex>
      </Box>
      <VStack
        position="absolute"
        top="30%"
        left={{ base: '50%', lg: '55%' }}
        maxH="50%"
        maxW={{ base: '40%', lg: '55%' }}
        alignItems="left"
      >
        <Box>
          <HighlightFirstWord
            fontFamily="heading"
            productLine={productLine ?? ''}
            as="h6"
          />
        </Box>
        <Heading
          as="h4"
          position="relative"
          fontSize="xl"
          _before={{
            position: 'absolute',
            bgColor: 'black',
            content: "''",
            left: '-60px',
            width: '50px',
            height: '1px',
            top: '50%',
          }}
        >{title}
        </Heading>
        <Box
          w="90%"
          css={css`
            @media (max-width: ${theme.breakpoints.lg}) {
              display: none;
            }
          `}
        >
          <Box
            fontSize="md"
            fontWeight={400}
            pb={3}
          >
            {text}
          </Box>
        </Box>
        <Box>
          {link.absolutePath && (
            <NextLink
              href={link.absolutePath}
            >
              <AnimatedUnderline
                fontSize="lg"
                display="inline-block"
              >
                {linkText}
              </AnimatedUnderline>
            </NextLink>
          )}
        </Box>
      </VStack>
    </Box>
  )
}

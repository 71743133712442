import { Box, Flex, Heading, Skeleton, SkeletonText, Stack, Text, useBreakpointValue } from '@chakra-ui/react'
import { AnimatedUnderline } from 'custom_components/elements/AnimatedUnderline/AnimatedUnderline'
import { HighlightFirstWord } from 'custom_components/elements/HighlightFirstWord/HighlightFirstWord'
import dayjs from 'dayjs'
import { NextLink } from 'design_system/utility/NextLink'
import { formatHeadlineColor } from 'helper/formatHeadlineColor'
import { asComponent } from 'helper/htmlComponentParser'
import NextImage from 'next/image'
import type React from 'react'
import { useIntl } from 'react-intl'

export interface INewsCardProps {
  displayDate: string
  image: IImage
  highlightedFirstWord?: string
  headline: string
  text: string
  link: {
    absolutePath: string
  }
  isLoaded?: boolean
}
interface IColors {
  colorText?: string
  colorBackground?: string
  colorAccent?: string
}
interface ICombined extends IColors, INewsCardProps {}

export const NewsCard: React.FC<ICombined> = ({
  image, headline, highlightedFirstWord, text, link, displayDate, isLoaded = true,
  colorText = '', colorBackground = '', colorAccent = '',
}) => {
  const isMobile = useBreakpointValue({
    base: 'true',
    lg: '',
  }, '')
  const intl = useIntl()
  return (
    <Stack
      direction={{ base: 'column', lg: 'row' }}
      w="100%"
      spacing={0}
      alignItems="center"
    >
      <Skeleton
        isLoaded={isLoaded}
        w={isLoaded ? '100%' : 'calc(100% - 30px)'}
        borderRadius="15px"
        px="15px"
      >
        <NextLink
          w="100%"
          href={`/news${link.absolutePath}`}
        >
          <NextImage
            src={image.fullpath ?? '/'}
            layout="responsive"
            width="446px"
            height="268px"
            objectFit="cover"
            placeholder={image.blurDataUrl ? 'blur' : 'empty'}
            blurDataURL={image.blurDataUrl}
          />
        </NextLink>
      </Skeleton>
      <Flex
        w="100%"
        h="100%"
        color={colorText}
        alignItems="center"
        alignContent="center"
        px="15px"
      >
        <Box>
          <Skeleton isLoaded={isLoaded} w={isLoaded ? undefined : '100px'} h={isLoaded ? undefined : '20px'} mt={isLoaded ? undefined : { base: 3, md: '0' }}>
            <Text
              fontSize="small"
              pt={{ base: 3, md: '0' }}
            >{dayjs(displayDate).format('DD.MM.YYYY')}
            </Text>
          </Skeleton>
          <Skeleton isLoaded={isLoaded} mt={isLoaded ? '0px' : '10px'} mb={isLoaded ? '0px' : '30px'}>
            <Heading
              as="h4"
              lineHeight="1.5rem"
              fontSize="xl"
              fontWeight={700}
              display="inline"
              fontFamily="primary"
            >
              <HighlightFirstWord productLine={highlightedFirstWord ?? ''}/>
              {formatHeadlineColor(headline)}
            </Heading>
          </Skeleton>
          <SkeletonText
            isLoaded={isLoaded}
            noOfLines={5}
            skeletonHeight="1rem"
            mb={isLoaded ? '0px' : '30px'}
            spacing={2}
          >
            <Box fontSize="md" letterSpacing="0.45px" py={3}>{asComponent(text)}</Box>
          </SkeletonText>
          <Skeleton isLoaded={isLoaded} w={isLoaded ? 'auto' : '100px'} h={isLoaded ? 'auto' : '20px'}>
            <AnimatedUnderline
              display="inline-block"
              fontFamily="secondary"
              isPermaActive={Boolean(isMobile)}
            >
              <NextLink fontWeight="bold" fontSize="md" href={`/news${link.absolutePath}`}>
                {intl.formatMessage({ id: '--read-more' })}
              </NextLink>
            </AnimatedUnderline>
          </Skeleton>
        </Box>
      </Flex>
    </Stack>
  )
}

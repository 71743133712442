import { Box, Container, Heading, SimpleGrid } from '@chakra-ui/react'
import { EmployeeCard } from 'design_system/compounds/EmployeeCard/EmployeeCard'
import type React from 'react'

export interface IEmployeeCardGridProps {
  headline: string
  people: Array<{
    image: IImage
    name: string
    position: string
    telephoneNumber: string
    email: string
    mobileNumber?: string
  }>
}
interface IColors {
  colorHeading?: string
}
interface ICombined extends IColors, IEmployeeCardGridProps {}

export const EmployeeCardGrid: React.FC<ICombined> = ({ headline, people, colorHeading = 'secondaryText.500' }) => {
  return (
    <Container
      maxW="container.page"
      pb={8}
    >
      <Box
        mt="35px"
      >
        <Heading
          as="h3"
          fontWeight={700}
          fontSize="3xl"
          color={colorHeading}
          mb={8}
        >{headline}
        </Heading>
        <SimpleGrid
          columns={{ base: 1, md: 2, xl: 3 }}
          justifyItems="left"
          spacing={8}
        >
          {people.map((person) => (
            <EmployeeCard
              key={person.image.fullpath}
              name={person.name}
              image={person.image}
              position={person.position}
              telephoneNumber={person.telephoneNumber}
              mobileNumber={person.mobileNumber}
              email={person.email}
            />
          ))}
        </SimpleGrid>
      </Box>
    </Container>
  )
}

import { Form } from 'design_system/compounds/Form/Form'
import type { IFormData } from 'design_system/compounds/Form/Form.types'
import { ButtonData, CheckboxData, InputData } from 'design_system/compounds/Form/Form.types'
import { asComponent } from 'helper/htmlComponentParser'
import type { NewsletterAddress } from 'operations/vendure/generated/generated'
import type React from 'react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'

export interface INewsletterSubscriptionFormData {
  onSubmit: (data: NewsletterAddress) => void
  isLoading: boolean
  isUnsubscribe?: boolean
}

export const NewsletterSubscriptionForm: React.FC<INewsletterSubscriptionFormData> = ({ onSubmit, isLoading, isUnsubscribe = false }) => {
  const { formatMessage } = useIntl()

  const validationSchema = useMemo(() => yup.object().shape({
    email: yup.string().email(formatMessage({ id: 'form--field-must-be-a-valid-email' })).required(formatMessage({ id: 'form--field-is-required' })),
    name: yup.string(),
    company: yup.string(),
    street: yup.string(),
    zip: yup.string(),
    city: yup.string(),
    ...(isUnsubscribe
      ? {}
      : {
          privacy: yup.boolean().equals([true], formatMessage({ id: 'form--field-is-required' })),
        }),
  }), [formatMessage, isUnsubscribe])

  const items: IFormData = useMemo(() => ({
    blocks: [
      [
        {
          fields: [
            [
              new InputData({ title: formatMessage({ id: '--name' }), name: 'name', type: 'text', minWidth: '300px' }),
              new InputData({ title: `${formatMessage({ id: '--email' })} *`, name: 'email', type: 'email', minWidth: '300px' }),
            ],
            [
              new InputData({ title: formatMessage({ id: '--company' }), name: 'company', type: 'text', minWidth: '300px' }),
              new InputData({ title: formatMessage({ id: '--street' }), name: 'street', type: 'text', minWidth: '300px' }),
            ],
            [
              new InputData({ title: formatMessage({ id: '--zip' }), name: 'zip', type: 'text', minWidth: '300px' }),
              new InputData({ title: formatMessage({ id: '--city' }), name: 'city', type: 'text', minWidth: '300px' }),
            ],
            [
              ...isUnsubscribe ? [] : [new CheckboxData({ title: asComponent(formatMessage({ id: 'register--accept-privacy-statement' })), name: 'privacy' })],
            ],
            [
              new ButtonData({ title: formatMessage({ id: '--submit' }), type: 'submit', inputProps: { maxWidth: '300px', colorScheme: 'accent', isLoading, mt: 5 } }),
            ],
          ],
        },
      ],
    ],

  }), [formatMessage, isLoading, isUnsubscribe])
  return (
    <Form
      blockGap="5"
      fieldGap="1rem"
      items={items}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    />
  )
}

import { Box, Heading, Stack, Text } from '@chakra-ui/react'
import { HighlightFirstWord } from 'custom_components/elements/HighlightFirstWord/HighlightFirstWord'
import { NextLink } from 'design_system/utility/NextLink'
import { formatHeadlineColor } from 'helper/formatHeadlineColor'
import NextImage from 'next/image'
import type React from 'react'
import type { ReactNode } from 'react'
import { useIntl } from 'react-intl'

export interface INewsCard {
  image: IImage
  headline: string
  text: ReactNode
  link: {
    absolutePath: string
  }
  highlightedFirstWord?: string
}
interface IColor {
  colorText?: string
  colorAccent?: string
  colorBackground?: string
}
interface ICombined extends INewsCard, IColor {}

export const FeaturedNewsCard: React.FC<ICombined> = ({
  image, headline, highlightedFirstWord, text, link,
  colorText = 'primaryText.500',
  colorAccent = 'accent.500',
  colorBackground = 'primaryBackground.500',
}) => {
  const intl = useIntl()
  return (
    <Box
      w={{ base: '400', sm: '360px', lg: '450px' }}
    >
      <Box
        transform={{ base: 'none', sm: 'translateX(-60px)', lg: 'none' }}
        position="relative"
      >
        <NextImage
          src={image.fullpath ?? '/'}
          layout="responsive"
          width="450px"
          height="300px"
          placeholder={image.blurDataUrl ? 'blur' : 'empty'}
          blurDataURL={image.blurDataUrl}
        />
      </Box>
      <NextLink href={`/news/${link.absolutePath}`}>
        <Stack
          p="24px"
          backgroundColor={colorBackground}
          w={{ base: '100%', sm: '355px' }}
          transform={{ base: 'translateY(-45px)' }}
          marginLeft="auto"
          cursor="pointer"
          _hover={{
            _after: {
              w: '70px',
            },
          }}
          _after={{
            content: '""',
            h: '2px',
            bgColor: colorAccent,
            w: '0px',
            position: 'absolute',
            transition: 'width 0.5s ease',
            top: 'full',
            bottom: '23px',
            maxW: '70px',
          }}
          color={colorText}
        >
          <Heading
            as="h4"
            lineHeight="1.5rem"
            fontSize="xl"
            fontWeight={700}
            display="inline"
            fontFamily="primary"
          >
            <HighlightFirstWord productLine={highlightedFirstWord ?? ''}/>
            {formatHeadlineColor(headline)}
          </Heading>
          <Box fontSize="md" letterSpacing="0.45px">{text}</Box>
          <Text fontSize="xl" fontFamily="primary">{intl.formatMessage({ id: '--more' })}</Text>
        </Stack>
      </NextLink>
    </Box>
  )
}

import { Box } from '@chakra-ui/react'
import type { INewsCardProps } from 'custom_components/compounds/NewsCard/NewsCard'
import { NewsCardContainer } from 'custom_components/modules/NewsCardContainer/NewsCardContainer'
import { Pagination } from 'design_system/compounds/Pagination/Pagination'
import { useRouter } from 'next/router'
import { useLatestNewsQuery } from 'operations/pimcore/generated/generated'
import { mapNewsResponseToProps } from 'operations/pimcore/helpers/news'
import type React from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

export interface ILatestNewsProps {
  totalCount: number
}

export const LatestNews: React.FC<ILatestNewsProps> = ({ totalCount }) => {
  const router = useRouter()
  const [news, setNews] = useState<INewsCardProps[]>([])
  const page = router.query.page

  const { data, isLoading, refetch } = useLatestNewsQuery({ first: 5, after: (+(page as string) - 1) * 5, lang: router.locale ?? 'de' }, { staleTime: 1000 * 60 * 10 })

  const ref = useRef(null)

  useEffect(() => {
    refetch()
    if (data?.getNewsPageListing?.edges) {
      setNews(mapNewsResponseToProps(data))
    }
  }, [data, page, refetch])

  useEffect(() => {
    if (!page) return
    // @ts-expect-error ...
    window.scrollTo({ top: ref.current?.offsetTop - 120, behavior: 'smooth' })
  }, [page])

  const setCurrentPage = useCallback(
    (newPage: number) => router.push(
      {
        pathname: '/neuigkeiten',
        query: { page: newPage },
      },
      undefined,
      { shallow: true },
    ),
    [router],
  )

  return (
    <Box ref={ref}>
      <NewsCardContainer news={news} isLoaded={!isLoading}/>
      <Box mx="auto" w="fit-content" py="30px">
        <Pagination
          variant="secondary"
          currentPage={page && typeof page === 'string' ? parseInt(page) : 1}
          itemsPerPage={5}
          totalItems={data?.getNewsPageListing?.totalCount ?? 0}
          setCurrentPage={setCurrentPage}
        />
      </Box>
    </Box>
  )
}

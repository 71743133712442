import type { As } from '@chakra-ui/react'
import { Box, Heading } from '@chakra-ui/react'
import type { INewsCardProps } from 'custom_components/compounds/NewsCard/NewsCard'
import type { ITextWithImageProps } from 'design_system/compounds/TextWithImage/TextWithImage'
import { TextWithImage } from 'design_system/compounds/TextWithImage/TextWithImage'
import { asImageProps, getDataUrl } from 'helper/backendHandler'
import { formatHeadlineColor } from 'helper/formatHeadlineColor'
import { asComponent } from 'helper/htmlComponentParser'
import NextImage from 'next/image'
import type { ElementsFragment, LatestNewsQuery, NewsQuery } from '../generated/generated'

export function prepareNewsResponse (newsResponse: NewsQuery) {
  const headerTitleColorOrFallback = newsResponse.getNewsItemBySlugOrPath?.newsItem?.headerTitleAccentColor ?? 'accent.500'
  const headerColorMap: Record<string, string> = { red: 'accent.500' }
  const headerTitleColor = headerTitleColorOrFallback in headerColorMap ? headerColorMap[headerTitleColorOrFallback] : headerTitleColorOrFallback

  return {
    elements: newsResponse.getNewsItemBySlugOrPath?.newsItem?.elements?.filter(element => element?.__typename) as ElementsFragment[] | undefined ?? [],
    header: newsResponse.getNewsItemBySlugOrPath?.newsItem?.headerImage?.image
      ? {
          image: asImageProps(newsResponse.getNewsItemBySlugOrPath.newsItem.headerImage.image),
          title: newsResponse.getNewsItemBySlugOrPath.newsItem.headerTitle ?? '',
          titleColor: headerTitleColor,
        }
      : null,
    seo: {
      title: newsResponse.getNewsItemBySlugOrPath?.newsItem?.seoTitle ?? '',
      description: newsResponse.getNewsItemBySlugOrPath?.newsItem?.seoDescription ?? '',
      canonicals: (newsResponse.getNewsItemBySlugOrPath?.newsItem?.canonicals?.filter(e => e?.absolutePath) as Array<{ absolutePath: string }> | undefined)?.map(e => ({ absolutePath: e.absolutePath })) ?? [],
    },
    headline: newsResponse.getNewsItemBySlugOrPath?.newsItem?.name ?? '',
  }
}

export const getNewsBlocks = (res: NewsQuery) => {
  return res.getNewsItemBySlugOrPath?.newsItem?.elements?.map((element, index) => {
    switch (element?.__typename) {
      case 'fieldcollection_Headline': {
        const props = {
          headlineType: element.headlineType,
          headline: element.headline,
        }
        return (
          <Heading fontSize={28} lineHeight={1.2} mb={5} key={`${element.__typename}-${index}`} as={props.headlineType as As}>{formatHeadlineColor(props.headline ?? '')}</Heading>
        )
      }
      case 'fieldcollection_Text': {
        const parsedText = asComponent(element.text ?? '')

        return (
          <Box fontSize="lg" mb={6}>{parsedText}</Box>
        )
      }
      case 'fieldcollection_Image': {
        return (
          <Box>
            <NextImage
              src={element.image?.fullpath ?? ''}
              layout="responsive"
              height="400px"
              width="600px"
              objectFit="cover"
              placeholder={getDataUrl(element.image) ? 'blur' : 'empty'}
              blurDataURL={getDataUrl(element.image)}
            />
          </Box>
        )
      }
      case 'fieldcollection_TextWithImage': {
        const props: ITextWithImageProps = {
          text: element.text ?? '',
          image: element.image?.fullpath ?? '',
          imageLeft: element.imagePosition === 'left',
        }

        return <Box mb={6}><TextWithImage {...props} key={`${element.__typename}-${index}`}/></Box>
      }
    }
  })
}

export const mapNewsResponseToProps = (res: LatestNewsQuery, highlightedFirstWord?: string, isLoaded?: boolean): INewsCardProps[] => {
  return res.getNewsPageListing?.edges?.map((edge, index) => ({
    displayDate: edge?.node?.displayDate ?? '',
    headline: edge?.node?.name ?? '',
    image: asImageProps(edge?.node?.image),
    link: {
      absolutePath: edge?.node?.absolutePath ?? '',
    },
    text: edge?.node?.text ?? '',
    highlightedFirstWord,
    isLoaded,
  }),
  ) ?? []
}

import { Box, Stack } from '@chakra-ui/react'
import { asComponent } from 'helper/htmlComponentParser'
import Image from 'next/image'
import type React from 'react'

export interface ITextWithImageProps {
  text: string
  image: string
  imageLeft?: boolean
}

export const TextWithImage: React.FC<ITextWithImageProps> = ({ text, image, imageLeft }) => {
  const parsedText = asComponent(text)
  return (
    <Box >
      <Box>
        <Stack direction={imageLeft ? 'row' : 'row-reverse'}>
          <Box w="40%" rounded="3px" overflow="hidden" pr={imageLeft ? { base: 0, xl: 3 } : {}} pl={!imageLeft ? { base: 0, xl: 3 } : {}}>
            <Image layout="responsive" height="200px" width="300px" src={image} objectFit="cover"/>
          </Box>
          <Box w="60%" textAlign="left" as="span" fontSize="lg">
            {parsedText}
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}
